import axios from 'axios'
import {settings} from "../setting/Pathes";

export const setClick = (type) => new Promise((resolve, reject) => {
    try {
        const id = window.navigator.userAgent.replace(/\D+/g, '')
        const click = id+'--'+type
        axios.post(`${settings.apiPath}/setUserClick/${click}`).then(res => resolve(res))
            .catch(e => reject(e))
    } catch (e) {
        return null;

    }
})
export const setPhoneCall= (no) => new Promise((resolve, reject) => {
    try {
        const id = window.navigator.userAgent.replace(/\D+/g, '')
        const click = id+'--'+no
        axios.post(`${settings.apiPath}/setUserCall/${click}`).then(res => resolve(res))
            .catch(e => reject(e))
    } catch (e) {
        return null;

    }
})

export const setVisit = (id) => {
    try {
        const formData = new FormData();
        formData.append('clickId', id)
        axios.post(`${settings.apiPath}/addVisit`, formData)
    } catch (e) {
        return null;
    }
}
