import React from 'react';
import {FormattedMessage} from "react-intl";
import './style.scss'

const Landing = (props) => {
    return (
        <div className={'landing-container'}>
            <div className="landing-wrapper">
                {/*<div className={`image-container ${loading == false ? 'animated fadeInUp' : ''}`}>*/}
                {/*    /!*<img src={require('../../images/gt.png')}/>*!/*/}
                {/*    <div className="logo-background"></div>*/}
                {/*</div>*/}
                <div className={'buildings-container'}>
                    <img src={require('../../images/buildings.png')} alt={'golden-mountains-landingpage'}/>
                    <img src={require('../../images/buildings.png')} alt={'golden-mountains-landingpage'}/>
                </div>
                <div className={'project-name-title'}>
                    <h1><FormattedMessage id={'compName'}/></h1>
                    <div className="landing-subtitle">
                        <div className="line"></div>
                        <h3><FormattedMessage id={'forInvestment'}/></h3>
                        <div className="line"></div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Landing;
