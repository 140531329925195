import React, {useContext, useEffect} from 'react';
import './style.scss'
import {useHistory, useParams} from "react-router-dom";
import {faArrowRight, faBuilding, faCity, faHome, faLocationArrow} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {AssetsContext} from "../../context/assetsContext";
import {FormattedMessage} from "react-intl";
import {faDesktop} from "@fortawesome/free-solid-svg-icons/faDesktop";
import {faDashcube} from "@fortawesome/free-brands-svg-icons";
import {setClick} from "../../assets/ApiFunctions";

const Projects = (props) => {
        const {handleLoading} = useContext(AssetsContext)

        const {setUserLanguage, language} = useContext(AssetsContext)
        const {lang} = useParams();
        let history = useHistory();
        useEffect(() => {
            window.scrollTo(0, 0)
            if (['en', 'ar', 'kr'].includes(lang)) {
                setUserLanguage(lang)
            } else {
                history.push('/app')
            }
        }, [])
        const color = '#c0aa71'
        const pushTo = () => {
            if (lang == undefined) {
                history.push(`/app/${language}/types`)
            } else history.push(`/app/${lang}/types`)


        }
        useEffect(() => {
            const onPageLoad = () => {
                handleLoading(false);
            };

            // Check if the page has already loaded
            if (document.readyState === "complete") {
                onPageLoad();
            } else {
                window.addEventListener("load", onPageLoad);
                // Remove the event listener when component unmounts
                return () => window.removeEventListener("load", onPageLoad);
            }
        }, []);

        return (
            <div className={'projects-container'} style={{direction: ['ar', 'kr'].includes(language) ? 'rtl' : 'ltr'}}>
                <h2 className={'section-title'}><FormattedMessage id={'ourProjects'}/></h2>
                <div className="project-wrapper">
                    <div className="project-content">
                        <div className="project-details" data-content={60}>
                            <div className="inprogress">
                                <div className="title"><FormattedMessage id={'underConstruction'}/></div>
                            </div>
                            <div className="cover"></div>
                            {/*<div className="image-title animated fadeInUp">GOLDEN M TOWERS</div>*/}
                            <img src={require('../../images/gt-gate.jpg')} alt="" onClick={() => pushTo()}/>
                            <div className={'project-details-describtion'}>
                                <span className={'project-title'} dir={'rtl'}><FormattedMessage id={'towerProject'}/></span>
                                <span className={'flex-column project-item'}>
                                    <span className={'project-subTitle'}> <FontAwesomeIcon icon={faLocationArrow}
                                                                                           size={"1x"}
                                                                                           color={color}/>:<FormattedMessage
                                        id={'location'}/> </span>
                                   <p className={'details'}> <FormattedMessage id={'locationDesc'}/>
                                    <a href="https://www.google.com/maps/place/Golden+M+Towers+Erbil/@36.2886509,44.0889329,17z/data=!3m1!4b1!4m5!3m4!1s0x4006e18c26a3aac1:0x13908d4eaae85b02!8m2!3d36.2886466!4d44.0911216"><FormattedMessage
                                        id={'locationClickHere'}/></a>
                                       </p>
                               </span>
                                <span className={'flex-column project-item'}>
                                    <span className={'project-subTitle'}><FontAwesomeIcon icon={faDashcube} size={"1x"} color={color}/>:<FormattedMessage id={'projectDescTitle'}/></span>
                                   <p className={'details'}> <FormattedMessage id={'projectDesc'}/> </p>
                                    {/*<a href={``} onClick={() => history.push(`/app/${lang}/sitePlan`)}><FormattedMessage id={'goToSitePlan'}/></a>*/}
                               </span>
                                {/*<span>Lorem ipsum dolor sit amet.</span>*/}
                                <div className={'gold-line'}></div>
                                <div className={'read-more'} onClick={() => pushTo()}>
                                    <div><FormattedMessage id={'readMore'}/></div>
                                    <FontAwesomeIcon icon={faArrowRight} size={"2x"} color={{
                                        fontcolor(color) {
                                            'var(--color-five)'
                                        }
                                    }} style={{transform: ['ar', 'kr'].includes(language) ? 'rotate(180deg)' : ''}}/>
                                </div>

                            </div>
                        </div>
                        <div className="project-details" data-content={50}
                             onClick={() => setClick('prestige')}>
                            <div className="inprogress">
                                <div className="title"><FormattedMessage id={'comingSoon'}/></div>
                            </div>
                            <div className="cover"></div>
                            <div className="image-title animated fadeInUp"><span>Coming Soon</span>GOLDEN CITY</div>
                            <div style={{overflow: 'hidden'}}>
                                <img className={'golden-blur'} src={require('../../images/goldenLine.jpg')} alt=""/>
                            </div>
                            <div className={'project-details-describtion'}>
                                <span className={'project-title'}><FormattedMessage id={'prestigProject'}/></span>
                                <span><FormattedMessage id={'comingSoon'}/></span>
                                <span><FormattedMessage id={'comingSoon'}/></span>
                                <div className={'gold-line'}></div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

;

export default Projects;
