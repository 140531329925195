import React, {createContext, useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
// import {} from "react-intl";

export const AssetsContext = createContext()


const AssetsProvider = (props) => {
    // const [langFromUrl, setLangFromUrl] = useState('ar')

    useEffect(async () => {
        let x = window.location.pathname.split('/')
        if (['ar', 'kr', 'en'].includes(x[2])) {
            await setLanguage(x[2])
            // console.log('context', langFromUrl)
        }else {
            await setLanguage('en')
        }

    }, [])
    const history = useHistory()

    const [loading, setLoading] = useState(true)

    const [language, setLanguage] = useState('');

    const handleLoading = (state) => {
        setLoading(state)
    }
    const setUserLanguage = (data) => {
        // history.push(`/app/home/${data}`);
        setLanguage(data);
    }
    return (
        <AssetsContext.Provider value={{
            language, setUserLanguage, loading, handleLoading
        }}>
            {props.children}
        </AssetsContext.Provider>
    )
};

export default AssetsProvider;
