export const settings={
    // r:`${window.location.host}/recipe/public/images`,
    // imagePath:'http://192.168.100.200/recipe/public/images',
                    apiPath:`https://goldenmountains.net/backEnd/public/index.php/api`,
                    // imagePath:`http://${window.location.hostname}:82/images`,
        // imagePath:'http://213.32.252.205/images',
    // apiPath:'http://127.0.0.1:8000/api'
    // apiPath:`/recipe/public/index.php/api`,
    // imagePath:`/recipe/public/images`,
}
