import React, {useEffect, useRef, useState} from 'react';
import './style.scss'
import {faHome, faKey, faHardHat, faBuilding, faCity,faParking} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import CountUp from "react-countup";
import {FormattedMessage} from "react-intl";

const Counter = (props) => {
        const [visible, setVisible] = useState(true)
        const conterRef = useRef(null)

        const color = 'var(--color-five)'
            return (
                <div ref={conterRef} style={{width:'100%',dicoration:'inharent'}}>
                    {
                    visible &&
                <div className={'counter-container'} >

                        <div className="counter-wrapper">
                            <div className="counter-content">
                                <div className="counter-details">
                                    <div><FontAwesomeIcon icon={faHome} size={"3x"} color={color}/></div>
                                    <span className={'numbers'}><CountUp start={0} end={2460} duration={5}/></span>
                                    <span className={'title'}><FormattedMessage id={'totalFlats'}/></span>
                                </div>
                                <div className="counter-details">
                                    <div><FontAwesomeIcon icon={faBuilding} size={"3x"} color={color}/></div>
                                    <span className={'numbers'}><CountUp start={0} end={16} duration={5}/>-<CountUp start={0} end={30} duration={5}/></span>
                                    <span className={'title'}><FormattedMessage id={'buildingFloors'}/></span>
                                </div>
                                <div className="counter-details">
                                    <div><FontAwesomeIcon icon={faCity} size={"3x"} color={color}/></div>
                                    <span className={'numbers'}><CountUp start={0} end={12} duration={5}/></span>
                                    <span className={'title'}><FormattedMessage id={'totalBuilding'}/></span>
                                </div>
                                {/*<div className="counter-details">*/}
                                {/*    <div><FontAwesomeIcon icon={faKey} size={"3x"} color={color}/></div>*/}
                                {/*    <span className={'numbers'}><CountUp start={0} end={36} duration={5}/></span>*/}
                                {/*    <span className={'title'}>Get Your Key</span>*/}
                                {/*</div>*/}
                                <div className="counter-details">
                                    <div><FontAwesomeIcon icon={faParking} size={"3x"} color={color}/></div>
                                    <span className={'numbers'}><CountUp start={0} end={2500} duration={5}/></span>
                                    <span className={'title'}><FormattedMessage id={'parkingLots'}/></span>
                                </div>

                            </div>
                        </div>
                </div>
                    }

                </div>

            )


}

;

export default Counter;
